'use client';

import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useRouter, usePathname } from 'i18n/routing';

import createStyles from '@guestyci/foundation/createStyles';
import Divider from '@guestyci/foundation/Divider';
import { Col, Row } from '@guestyci/foundation/Layout';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import TextField from '@guestyci/foundation/TextField/TextField';
import t from '@guestyci/localize/t.macro';

import Carousel from 'components/Carousel';
import NextBreadcrumb from 'components/Breadcrumbs/NextBreadcrumbs';
import Amenities from 'components/Amenities';
import HotelFeatures from 'components/HotelFeatures';
import Map from 'components/MapContainer';
import ContactForm from 'components/ContactForm';
import FullPageLoader from 'components/FullPageLoader';
import FeaturedListings from 'components/FeaturedListings';
import PropertyRate from 'components/PropertyRate';
import BedArrangement from 'components/BedArrengement';
import HouseRules from 'components/HouseRules';
import BookNowGroupReserations from 'components/BookNow/BookNowGroupReservations';
import BookNow from 'components/BookNow';

import useGetListingById from 'hooks/useGetListingById';
import useDio from 'hooks/useDio';
import useSearchValues from 'hooks/useSearchValues';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import useIsGroupReservation from 'hooks/useIsGroupReservation';

import { BED_ARRANGEMENT, HOUSE_RULES } from 'constants/featureToggleNames';
import { useAnalytics } from 'analytics/hooks';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  sliderWrapper: {
    padding: '20px 0px 20px',
    width: '100%',
    [create('xs')]: {
      paddingTop: 0,
      paddingBottom: 20,
    },
  },
  searchWrapper: {
    position: 'absolute',
    [create('xs')]: {
      left: 0,
      right: 0,
      marginLeft: 10,
      marginRight: 10,
      marginTop: -160,
    },
    [create('md')]: {
      width: 664,
      left: '50%',
      marginLeft: -332,
    },
    [create('lg')]: {
      marginTop: -44,
      top: 0,
    },
  },
  contentWrapper: {
    position: 'relative',
    background: '#FAFAFA',
    maxWidth: 1500,
    width: '100%',
    borderRadius: 20,
    [create('xs')]: {
      padding: '20px 10px 30px',
    },
    [create('lg')]: {
      padding: '60px 30px 60px',
    },
  },
  propertyDescription: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    justifyContent: 'center',
    [create('md')]: {
      maxWidth: 550,
    },
    [create('lg')]: {
      maxWidth: 700,
    },
  },
  propertyDescriptionWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [create('xs')]: {
      flexDirection: 'column-reverse',
    },
    [create('md')]: {
      flexDirection: 'row',
    },
  },
  descriptionText: {
    marginTop: 30,
    whiteSpace: 'pre-wrap',
  },
  desciptionSection: {
    whiteSpace: 'pre-wrap',
    marginTop: 15,
  },
  showAll: {
    cursor: 'pointer',
    marginTop: 10,
  },
  root: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  suggestedSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1203,
    width: '100%',
    borderTop: '1px solid #D4D8E1',
    marginTop: 80,
    paddingTop: 50,
    [create('xs')]: {
      marginTop: 40,
      padding: '25px 20px 40px',
      overflow: 'hidden',
    },
    [create('md')]: {
      overflow: 'initial',
    },
  },
  contactsWrapper: {
    background: '#F5F5F5',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    [create('xs')]: {
      padding: '20px 10px',
    },
  },
  rateWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 0 20px',
    [create('md')]: {
      padding: '13px 0 0 0',
    },
  },
}));

const BookWidget = ({ property }) => {
  const { isGroupReservation, isGroupReservationEnabled, isLoading } = useIsGroupReservation();
  if (isLoading) {
    return <FullPageLoader />;
  }

  if (isGroupReservationEnabled) {
    return <BookNowGroupReserations property={property} isGroupReservation={isGroupReservation} />;
  }
  return <BookNow property={property} />;
};

const PropertyPage = ({ id, locale }) => {
  const { getPathWithQueryParams } = useGetPathToNavigate();
  const router = useRouter();
  const pathname = usePathname();
  const [isAnalyticsSent, setIsAnalyticsSent] = useState(false);

  const { checkInDateLocalized, checkOutDateLocalized, pointofsale, minOccupancy } = useSearchValues();
  const { gtagEcommerceViewItem, fbtrack } = useAnalytics();
  //  see segment split DISCO-house-rules-and-bed-arrangements
  const [, isBedArrangementEnabled] = useFeatureToggle(BED_ARRANGEMENT);
  const [, isHouseRulesEnabled] = useFeatureToggle(HOUSE_RULES);
  const { property, isGettingListing, isGettingPropertyError, error } = useGetListingById({
    id,
    params: {
      fields: `${isBedArrangementEnabled ? 'bedArrangements' : ''} ${isHouseRulesEnabled ? 'unitTypeHouseRules' : ''}`,
      lang: locale,
    },
  });

  const breadcrumbs = [
    {
      label: t('Home'),
      url: ('/properties')
    },
    {
      label: property?.title,
      url: pathname,
    }
  ];

  const {
    root,
    sliderWrapper,
    contentWrapper,
    propertyDescriptionWrapper,
    propertyDescription,
    descriptionText,
    showAll,
    suggestedSectionWrapper,
    contactsWrapper,
    rateWrapper,
    desciptionSection,
  } = useStyles();

  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const showAmenitiesLabel = showAllAmenities ? t('Show less') : t('Show all');

  const { dioTrack } = useDio();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!property) return;
    const {
      _id,
      nickname,
      title,
      prices: { currency, basePrice },
      parentId = null,
      type,
    } = property;

    if (type === 'MTL_CHILD' && parentId) {
      const path = getPathWithQueryParams({ destinationPath: `/properties/${parentId}` });
      router.push(path, { locale })
    }

    dioTrack('view_selected_item', 'pageview', {
      listings: [
        {
          listing_id: _id,
          listing_nickname: nickname,
          item_title: title,
          currency,
          price: basePrice,
          quantity: 1,
        },
      ],
    });

    if (checkInDateLocalized && checkOutDateLocalized && minOccupancy && !isAnalyticsSent) {
      setIsAnalyticsSent(true);
      gtagEcommerceViewItem({
        listingId: property._id,
        listingName: property.title,
        totalPrice: property.prices.basePrice,
        currency: property.prices.currency,
        checkInDate: checkInDateLocalized || null,
        checkOutDate: checkOutDateLocalized || null,
        numberOfGuests: minOccupancy || null,
        pointOfSale: pointofsale,
      });
      fbtrack('ViewContent', {
        listing_id: [property._id],
        content_name: property.title,
        content_type: property.propertyType,
        currency: property.prices.currency,
        value: property.prices.basePrice,
        num_guests: minOccupancy,
        check_in_date: checkInDateLocalized,
        check_out_date: checkOutDateLocalized,
        point_of_sale: pointofsale,
      });
    }
  }, [
    checkInDateLocalized,
    checkOutDateLocalized,
    dioTrack,
    fbtrack,
    getPathWithQueryParams,
    gtagEcommerceViewItem,
    locale,
    isAnalyticsSent,
    minOccupancy,
    pointofsale,
    property,
    router,
  ]);

  if (isGettingPropertyError) {
    return `${t('Error!')} ${error.message}`;
  }

  const {
    access = null,
    neighborhood = null,
    interactionWithGuests = null,
    notes = null,
    space = null,
    summary = null,
    transit = null,
  } = property?.publicDescription || {};

  const renderDescription = (title, value) => {
    if (value) {
      return (
        <div className={desciptionSection}>
          <TextField bold>
            <h2 className="inherit-all">{title}</h2>
          </TextField>
          <TextField>
            <p className="inherit-all">{parse(value)}</p>
          </TextField>
        </div>
      );
    }
  };

  return (
    <>
      {isGettingListing && <FullPageLoader />}
      {property && (
        <div className={root}>
          <div data-property-slider className={sliderWrapper}>
            <Carousel images={property.pictures} />
          </div>
          <div className={contentWrapper}>
            <NextBreadcrumb breadcrumbs={breadcrumbs} />
            {property?.reviews.total > 0 && (
              <div className={rateWrapper}>
                <PropertyRate inline property={property} />
              </div>
            )}
            <div className={propertyDescriptionWrapper}>
              <div data-property-description className={propertyDescription}>
                <TextField className="text-break" variant="h1">
                  <h1 className="inherit-all break-line-all">{property.title}</h1>
                </TextField>
                <TextField color="secondary">{property.subTitle}</TextField>
                <TextField bold variant="h4" className="mt-4">
                  <h2 className="inherit-all">{t('Description')}</h2>
                </TextField>
                <TextField className={descriptionText}>
                  <p className="inherit-all">{summary}</p>
                </TextField>
                {renderDescription(t('The Space'), space)}
                {renderDescription(t('Guest Access'), access)}
                {renderDescription(t('Neighborhood'), neighborhood)}
                {renderDescription(t('Interaction'), interactionWithGuests)}
                {renderDescription(t('Other things to note'), notes)}
                {renderDescription(t('Getting Around'), transit)}
                <Col className="mt-4">
                  <TextField bold>
                    <h2 className="inherit-all">{t('Check in and out')}</h2>
                  </TextField>
                  <Col>
                    <TextField>
                      {t('Check in:')}
                      {property.defaultCheckInTime}
                    </TextField>
                    <TextField>
                      {t('Check out:')}
                      {property.defaultCheckOutTime}
                    </TextField>
                  </Col>
                </Col>
                <Divider className="mt-6 mb-6" />
                <div>
                  <TextField bold className="mb-2">
                    <h2 className="inherit-all">
                      {t('Property features')}
                    </h2>
                  </TextField>
                  <Row>
                    <HotelFeatures
                      beds={property?.beds}
                      bathrooms={property?.bathrooms}
                      bedrooms={property?.bedrooms}
                      guests={property?.guests}
                    />
                  </Row>
                </div>
                {!!property?.amenities.length && (
                  <>
                    <Divider className="mt-6 mb-6" />
                    <div>
                      <TextField bold className="mb-2">
                        <h2 className="inherit-all">{t('Amenities')}</h2>
                      </TextField>
                      <Row wrap>
                        <Amenities showAll={showAllAmenities} amenities={property?.amenities} />
                      </Row>
                      <TextField onClick={() => setShowAllAmenities((prev) => !prev)} className={showAll} color="link">
                        {showAmenitiesLabel}
                      </TextField>
                    </div>
                  </>
                )}
                {property?.unitTypeHouseRules?.houseRules && (
                  <>
                    <Divider className="mt-6 mb-6" />
                    <div>
                      <TextField bold className="mb-2">
                        {t('House rules')}
                      </TextField>
                      <Row>
                        <HouseRules unitTypeHouseRules={property.unitTypeHouseRules} listingId={id} />
                      </Row>
                    </div>
                  </>
                )}
                {property?.bedArrangements?.bedrooms?.length && (
                  <>
                    <Divider className="mt-6 mb-6" />
                    <div>
                      <TextField bold className="mb-2">
                        {t('Room arrangement')}
                      </TextField>
                      <Row>
                        <BedArrangement bedrooms={property.bedArrangements.bedrooms} listingId={id} />
                      </Row>
                    </div>
                  </>
                )}
                <Divider className="mt-6 mb-6" />
                {property?.fullDescription && (
                  <>
                    <Divider className="mt-6 mb-6" />
                    <Col>
                      <TextField bold className="mb-2">
                        <h2 className="inherit-all">{t('Description')}</h2>
                      </TextField>
                      <TextField className="mb-6">{property?.fullDescription}</TextField>
                    </Col>
                  </>
                )}
                <Map circle center={property?.address} />
              </div>
              <BookWidget property={property} />
            </div>
          </div>
          <div className={suggestedSectionWrapper}>
            <FeaturedListings context="property_page" />
          </div>
          <ContactForm wrapper={contactsWrapper} />
        </div>
      )}
    </>
  );
};

export default PropertyPage;
