'use client';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import cn from 'classnames';
import createStyles from '@guestyci/foundation/createStyles';

import Slider from 'react-slick';
import Icon from 'components/Icon';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  rowWrapper: {
    display: 'flex',
    [create('xs')]: {
      display: 'block',
    },
    [create('md')]: {
      padding: 0,
    },
    [create('lg')]: {
      display: 'flex',
      padding: '10px 0px',
      justifyContent: 'center',
    },
  },
  mainSlider: {
    width: ({ fullWidth }) => fullWidth && '100%',
    [create('md')]: {
      width: '100%',
    },
    [create('lg')]: {
      width: 1065,
    },
    '& > .slick-list': {
      overflow: 'hidden',
      [create('xs')]: {
        width: '100%',
        height: 300,
        borderRadius: 0,
      },
      [create('md')]: {
        height: 600,
        borderRadius: 0,
      },
      [create('xl')]: {
        borderRadius: ({ fullWidth }) => (fullWidth ? '0' : '40px 0px 0px 40px'),
      },
      [create('xlg')]: {
        height: 660,
      },
    },
    '& .slick-dots': {
      bottom: 10,
      '& li button:before': {
        opacity: 1,
        color: '#BDBDBD',
        fontSize: 8,
      },
      '& li.slick-active button:before': {
        color: '#1BBCEF',
        opacity: 1,
      },
      [create('xs')]: {
        display: 'none',
      },
    },
  },
  thumbSlider: {
    width: 392,
    marginLeft: 40,
    borderRadius: '0px 40px 40px 0px',
    overflow: 'hidden',
    [create('xs')]: {
      display: 'none',
    },
    [create('xl')]: {
      display: 'flex',
      flexDirection: 'column',
      height: 600,
    },
    [create('xlg')]: {
      height: 660,
    },
  },
  thumbImage: {
    height: 200,
    width: '100%',
    maxWidth: 392,
    '&:not(:last-child)': {
      marginBottom: 40,
    },
    '& > img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    [create('xl')]: {
      height: 173,
    },
    [create('xlg')]: {
      height: 200,
    },
  },
  swiperThumb: {},
  sliderImage: {
    [create('xs')]: {
      height: 300,
    },
    [create('md')]: {
      height: 600,
    },
    [create('xlg')]: {
      height: 660,
    },
    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  arrow: {
    width: 50,
    height: 50,
    '&.slick-next::before, &.slick-prev::before': {
      display: 'none',
    },
    '&.slick-next': {
      right: 10,
    },
    '&.slick-prev': {
      left: 10,
      zIndex: 1,
    },
  },
}));

const CustomArrow = ({ onClick, icon, className }) => {
  const { arrow } = useStyles();
  return (
    <div className={cn(arrow, className)} onClick={onClick}>
      <Icon icon={icon} size={45} />
    </div>
  );
};

const settingsMain = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  lazyLoad: true,
  nextArrow: <CustomArrow icon="arrow_next" />,
  prevArrow: <CustomArrow icon="arrow" />,
  dots: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: false,
      },
    },
  ],
};

const Carousel = ({ images }) => {
  const renderThumb = images && images.length > 2;
  const { mainSlider, thumbSlider, thumbImage, sliderImage, rowWrapper } = useStyles();

  return (
    <div className={rowWrapper}>
      <Slider className={mainSlider} {...settingsMain}>
        {images.map((image) => (
          <div key={image.original} className={sliderImage}>
            <img src={image.original} alt={image?.caption || ''} />
          </div>
        ))}
      </Slider>
      {renderThumb && (
        <div className={thumbSlider}>
          {images.map((img, i) => {
            if (i < 3) {
              return (
                <div key={img.original} className={thumbImage}>
                  <img src={img.original} alt={img?.caption || ''} />
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;
