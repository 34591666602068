'use client';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField/TextField';
import { Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro';
import Icon from '@guestyci/foundation/Icon';

import IconGuests from 'assets/icons/guests.svg';
import useGetRoomsLabel from 'hooks/useGetRoomsLabel';

const GuestsCount = ({ count }) => {
  return (
    <Row align="center" spacing={1}>
      <TextField>{count}</TextField>
      <Icon svg={IconGuests} width={16} height={16} />
    </Row>
  );
};

const useStyles = createStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 4,
    padding: 15,
    marginBottom: 20,
    userSelect: 'none',
  },
}));

const Rooms = ({ roomsCount = 0, guestsCount = 0 }) => {
  const { root } = useStyles();
  const { isRoomLabel } = useGetRoomsLabel();
  if (!roomsCount || !guestsCount) return null;

  const roomLabel = t('Rooms x{num}', { num: roomsCount });
  const unitLabel = t('Units x{num}', { num: roomsCount });
  const label = isRoomLabel ? roomLabel : unitLabel;
  return (
    <Row className={root}>
      <TextField bold variant="h5">
        {label}
      </TextField>
      <GuestsCount count={guestsCount} />
    </Row>
  );
};

export default Rooms;
