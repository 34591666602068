'use client';

import reduce from 'lodash/reduce';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField/TextField';
import { Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro/t.macro';

import { houseRulesWithTranslation } from 'constants/houseRules';
import { HouseRuleItem } from './HouseRuleItem';

const HOUSE_RULES = {
  PETS_ALLOWED: 'petsAllowed',
  QUIET_BETWEEN: 'quietBetween',
  CHILDREN_RULES: 'childrenRules',
  PETS_CHARGE_TYPE: 'petStayCharge',
};

const useStyles = createStyles((theme) => ({
  additionalRules: {
    margin: `${theme.spacer(6)}px 0 0`,
    whiteSpace: 'pre-wrap',
  },
}));

const prepareHouseRulesIcons = ({ rules, iconsList }) => {
  const getIcon = ({ value = {}, icon, defaultIcons = iconsList }) => {
    const iconName = value?.enabled || value?.[icon] ? icon : `not${icon.charAt(0).toUpperCase() + icon.slice(1)}`;

    return defaultIcons.find((item) => iconName === item?.icon) || {};
  };

  const getChildrenRulesDetails = (value = {}, houseRulesIcon) => {
    return {
      text: houseRulesIcon.text,
      ...getIcon({ value, icon: houseRulesIcon.icon }),
      details: !value?.[houseRulesIcon.icon] && value?.reason,
    };
  };

  const getGeneralRulesDetails = ({ value = {}, houseRulesIcon, key }) => {
    let note = '';
    let details = '';
    if (key === HOUSE_RULES.QUIET_BETWEEN && value?.enabled) {
      note = value?.hours && `(${value?.hours?.start} ${t(' to ')} ${value?.hours?.end})`;
    }
    if (key === HOUSE_RULES.PETS_ALLOWED && value?.enabled) {
      note = value?.chargeType === HOUSE_RULES.PETS_CHARGE_TYPE ? t('(additional fee)') : t('(no additional fee)');
      details =
        value?.chargeType === HOUSE_RULES.PETS_CHARGE_TYPE
          ? t("Contact us to let us know you're bringing your pet, and to get details about the additional fee.")
          : '';
    }
    return {
      ...houseRulesIcon,
      ...getIcon({ value, icon: houseRulesIcon.icon }),
      note,
      details,
    };
  };

  return reduce(
    rules,
    (rulesAcc, value, key) => {
      const items = iconsList.reduce((acc, houseRulesIcon) => {
        if (houseRulesIcon.icon === key) {
          acc.push(getGeneralRulesDetails({ value, houseRulesIcon, key }));
        }
        if (key === HOUSE_RULES.CHILDREN_RULES && typeof value?.[houseRulesIcon.icon] === 'boolean') {
          acc.push(getChildrenRulesDetails(value, houseRulesIcon));
        }

        return acc;
      }, []);

      rulesAcc.push(...items);
      return rulesAcc;
    },
    []
  );
};

export const HouseRules = ({ unitTypeHouseRules }) => {
  const translations = houseRulesWithTranslation();
  const houseRules = unitTypeHouseRules?.houseRules;
  const { additionalRules } = useStyles();
  const houseRulesArray = Object.keys(translations).map((key) => ({
    ...translations[key],
  }));

  const houseRulesIcons = prepareHouseRulesIcons({ rules: houseRules, iconsList: houseRulesArray });

  return (
    <div>
      <Row align="baseline" wrap="no-wrap" justify="flex-start">
        {houseRulesIcons.map(({ text, icon, details, note = '' }) => (
          <HouseRuleItem key={text + JSON.stringify(icon)} text={text} icon={icon} details={details} note={note} />
        ))}
      </Row>
      {houseRules.additionalRules && (
        <div className={additionalRules}>
          <TextField bold className="mb-2">
            {t('Additional rules')}
          </TextField>
          <TextField>{houseRules.additionalRules}</TextField>
        </div>
      )}
    </div>
  );
};

export default HouseRules;
