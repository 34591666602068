'use client';

import _ from 'lodash';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

const DAY_STATUS = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  RESERVED: 'reserved',
  BOOKED: 'booked',
};

/**
 * @param {moment|Date} date1
 * @param {moment|Date} date2
 * @returns {Boolean}
 */
const isSameDate = (date1, date2) => moment(date1).isSame(date2, 'day');

const formatDate = (date) => moment(date).format(DATE_FORMAT);

/**
 * @param {moment|Date|String} date
 * @param {Number} days
 * @return {moment|undefined}
 */
const dateAddDays = (date, days) => {
  if (!date) return undefined;
  return moment(date).add(days, 'days');
};

/**
 * @typedef {Object} CalendarDay
 * @property {String} date - e.g: '2022-05-01'
 * @property {Number} minNights
 * @property {String} status - 'available'|'unavailable'|'reserved'|'booked'
 * @property {Boolean} cta
 * @property {Boolean} ctd
 */

/**
 * Parse date as local and set default time
 * NOTE: `new Date('2022-04-13')` are parsed as UTC - '2022-04-13T00:00:00.000Z'
 *
 * @param {String|Date|moment} [value]
 * @returns {moment}
 */
const parseDateAsLocal = (value = undefined) => {
  if (!value) return undefined;
  return moment(value).startOf('day');
};

const startOfMonth = (currentMonth) => {
  if (!currentMonth) return undefined;
  return moment(currentMonth).startOf('month');
};

const getBlockedMonth = (currentMonth) => {
  const date = startOfMonth(currentMonth);

  const monthIdx = date.month();
  const disabledDates = [];
  for (let i = 1; i <= 31; i += 1) {
    date.date(i);
    if (date.month() !== monthIdx) {
      return disabledDates;
    }
    disabledDates.push(formatDate(date));
  }
  return disabledDates;
};

/**
 * IMPORTANT: helper converts all dates to `Date` for consistency and to avoid addition conversion during checks
 * NOTE: react-day-picker use dates (`Date`) in local TZ stripping time
 * @see https://react-day-picker.js.org/basics/modifiers
 */
export default class AvailabilityHelper {
  /**
   *
   * @param {CalendarDay[]} calendar
   */
  constructor({ calendar }) {
    this.calendar = _.chain(calendar)
      ?.map((day) => ({
        ...day,
        date: parseDateAsLocal(day.date),
      }))
      .sortBy(this.calendar, 'date')
      .value();
  }

  /**
   * @param {Date|String} currentMonthRaw - YYYY-MM-DD
   * @param {Date|String} checkOutRaw - YYYY-MM-DD
   * @returns {Array<String>} - [YYYY-MM-DD]
   */
  getCheckInDisabledDays({ currentMonth: currentMonthRaw, checkOutDate: checkOutRaw } = {}) {
    const currentMonth = startOfMonth(currentMonthRaw);
    if (currentMonth && this.isOutsideCalendar(currentMonth)) {
      return getBlockedMonth(currentMonth);
    }

    const checkOutDate = parseDateAsLocal(checkOutRaw);
    const firstDate = this.getFirstAvailableDate();
    const lastDate = this.getLastAvailableDate(checkOutDate);
    const visibleRange = this.getVisibleRange(currentMonth);
    const checkOutIdx = checkOutDate && _.findIndex(this.calendar, (day) => isSameDate(day.date, checkOutDate));
    const checkOutDay = this.calendar[checkOutIdx];
    const lastBlockedDate =
      checkOutDate &&
      _.findLast(
        this.calendar,
        (day) => day.date < checkOutDay.date && day.status !== DAY_STATUS.AVAILABLE,
        checkOutIdx - 1
      )?.date;
    const isCheckInDisabled = (day) => {
      return !!(
        day.status !== DAY_STATUS.AVAILABLE ||
        day.cta ||
        (checkOutDay &&
          (day.date > dateAddDays(checkOutDay.date, -(day.minNights || 1)) ||
            (_.isNumber(day.maxNights) && day.date < dateAddDays(checkOutDay.date, -day.maxNights))))
      );
    };

    return visibleRange.reduce((acc, day) => {
      const isInRange = day.date >= (lastBlockedDate ?? firstDate) && day.date <= lastDate;
      const disabled = isInRange ? isCheckInDisabled(day) : true;
      if (disabled) acc.push(formatDate(day.date));
      return acc;
    }, []);
  }

  /**
   * @param {Date|String} [currentMonth] - YYYY-MM-DD
   * @param {Date|String} [checkInRaw] - YYYY-MM-DD
   * @returns {Array<String>} - [YYYY-MM-DD]
   */
  getCheckOutDisabledDays({ currentMonth: currentMonthRaw, checkInDate: checkInRaw } = {}) {
    const currentMonth = startOfMonth(currentMonthRaw);
    if (currentMonth && this.isOutsideCalendar(currentMonth)) {
      return getBlockedMonth(currentMonth);
    }

    const checkInDate = parseDateAsLocal(checkInRaw);
    const firstDate = this.getFirstAvailableDate(checkInDate);
    const lastDate = this.getLastAvailableDate();
    // note: checkIn could be out of current month
    const visibleRange = this.getVisibleRange(currentMonth);
    const checkInIdx = checkInDate && _.findIndex(this.calendar, (day) => isSameDate(day.date, checkInDate));
    const checkInDay = this.calendar[checkInIdx];
    const maxNightsBlockedDate = _.isNumber(checkInDay?.maxNights) && dateAddDays(checkInDay.date, checkInDay.maxNights);
    const firstBlockedDate =
      checkInDay &&
      _.find(
        this.calendar,
        (day) =>
          (maxNightsBlockedDate && day.date >= maxNightsBlockedDate) ||
          (day.date > checkInDay.date && day.status !== DAY_STATUS.AVAILABLE),
        checkInIdx + 1
      )?.date;
    const isCheckOutDisabled = ({ day, prevDay }) => {
      return !!(
        (prevDay && prevDay.status !== DAY_STATUS.AVAILABLE) || // checkOut on date when someone already has checkOut
        day.ctd ||
        (checkInDay && day.date < dateAddDays(checkInDay.date, checkInDay.minNights || 1))
      );
    };

    return visibleRange.reduce((acc, day, idx) => {
      const isInRange = day.date >= firstDate && day.date <= (firstBlockedDate ?? lastDate);
      const prevDay = visibleRange[idx - 1];
      const disabled = isInRange ? isCheckOutDisabled({ day, prevDay }) : true;
      if (disabled) acc.push(formatDate(day.date));
      return acc;
    }, []);
  }

  /**
   * @private
   * @param {Date|String} currentMonth
   * @returns {CalendarDay[]}
   */
  getVisibleRange(currentMonth) {
    if (!currentMonth) {
      return this.calendar;
    }

    const monthStart = startOfMonth(currentMonth);
    const startIdx = _.sortedIndexBy(this.calendar, { date: monthStart }, (day) => day?.date?.valueOf());
    const lastIdx = _.findLastIndex(this.calendar, (d) => d?.date?.month() === monthStart.month(), startIdx + 31);
    return this.calendar.slice(startIdx, lastIdx + 1);
  }

  /**
   * @private
   * @protected {Date} currentMonth
   * @returns {Boolean}
   */
  isOutsideCalendar(currentMonth) {
    const lastDate = this.calendar[this.calendar.length - 1]?.date;
    return currentMonth > lastDate;
  }

  /**
   * Returns first available date
   * @protected
   * @param {Date} checkInDate
   * @returns {Date|undefined}
   */
  getFirstAvailableDate(checkInDate = undefined) {
    // first available date should be >= current date and first date in calendar
    // note: checkIn date is available for checkIn
    return checkInDate ?? this.calendar[0]?.date;
  }

  /**
   * Returns last available date
   * @protected
   * @param {Date} checkOutDate
   * @returns {Date|undefined}
   */
  getLastAvailableDate(checkOutDate = undefined) {
    // last available date should be <= last date in calendar
    // note: checkOut date is available for checkOut
    return checkOutDate ?? this.calendar[this.calendar.length - 1]?.date;
  }
}
